<template>
  <div>
    <header class="sticky top-0 z-10">
      <div
        class="bg-navigation-800 flex h-[var(--mobile-search-header-height)] flex-row content-center items-center px-24 sm:hidden"
      >
        <fa-icon class="w-24 cursor-pointer text-white" :icon="icon" size="xl" @click="toggleShowSidebar" />
      </div>
      <transition name="slide-fade">
        <navigation-sidebar
          v-show="showSidebar"
          class="fixed bottom-0 left-0 top-[var(--mobile-search-header-height)] sm:top-0 sm:!flex"
        />
      </transition>
    </header>
    <div
      class="relative flex min-h-[calc(100vh-var(--mobile-header-height))] flex-col bg-gray-50 px-24 dark:bg-gray-800 sm:ml-[var(--navigation-width)] sm:min-h-screen"
    >
      <main class="flex grow text-gray-700 dark:text-gray-100">
        <notification-banner />
        <slot />
        <support-button />
      </main>
      <footer>
        <nav aria-labelledby="footer-navigation">
          <h2 id="footer-navigation" v-t="'layout.default.footerSectionLabel'" class="sr-only" />
          <ul class="text-13 flex w-full flex-col items-center py-16 text-gray-400 sm:flex-row sm:justify-between">
            <li>
              {{ $t('layout.default.copyRightText', { year: currentYear }) }}
            </li>
            <li class="mt-8 sm:mt-0">
              <nuxt-link :to="privacyPolicyUrl" target="_blank">
                {{ $t('layout.default.privacyLink') }}
              </nuxt-link>
            </li>
          </ul>
        </nav>
      </footer>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { privacyPolicyUrl } = storeToRefs(useDashboardCustomization());

const showSidebar = ref(false);

const toggleShowSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const icon = computed(() => (showSidebar.value ? 'fa-regular fa-times' : 'fa-regular fa-bars'));
const currentYear = computed(() => new Date().getFullYear());
</script>

<style scoped>
.slide-fade-enter-active {
  @apply transition-[transform,opacity] duration-300 ease-out;
}

.slide-fade-leave-active {
  @apply transition-[transform,opacity] duration-300 ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  @apply -translate-x-[var(--navigation-width)] opacity-0;
}
</style>
