<template>
  <v-dropdown theme="navigation-dropdown" class="w-full" :aria-id="id">
    <slot></slot>
    <template #popper="binding">
      <slot name="popper" v-bind="binding"></slot>
    </template>
  </v-dropdown>
</template>

<script setup lang="ts">
// see: https://github.com/Akryum/floating-vue/issues/1006
const id = useId();
</script>

<style>
.v-popper--theme-navigation-dropdown .v-popper__arrow-container {
  @apply !hidden;
}

.v-popper--theme-navigation-dropdown .v-popper__inner {
  @apply rounded-4 !bg-white px-8 py-4 dark:!bg-black dark:!text-white;
  @apply !border-none;
}
</style>
