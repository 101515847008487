<template>
  <bb-button
    v-if="buttonVisible"
    ref="buttonRef"
    custom="primary"
    class="!fixed bottom-40 right-24 rounded-full"
    @click="showPopup"
  >
    <font-awesome-icon :icon="faQuestionCircle" class="mr-8" />
    <span>{{ $t('components.supportButton.label') }}</span>
  </bb-button>

  <div
    v-if="popupVisible"
    ref="popupRef"
    :style="floatingStyles"
    class="rounded-8 h-fit w-[30rem] overflow-hidden border border-gray-50 bg-white dark:border-gray-900 dark:bg-black"
  >
    <div class="flex items-center justify-between px-20 py-12">
      <span>{{ $t('components.supportButton.popup.header') }}</span>
      <bb-button-icon :icon="faTimes" class="ml-8" @click="hidePopup" />
    </div>
    <div class="flex h-full flex-col gap-16 bg-gray-50 p-20 dark:bg-gray-800">
      <span class="text-17 font-semibold">{{ $t('components.supportButton.popup.title') }}</span>
      <span class="text-13">{{ $t('components.supportButton.popup.requiredHint') }}</span>
      <bb-input v-model="subject" name="subject" :label="$t('components.supportButton.popup.inputSubject')" />
      <bb-textarea v-model="body" name="body" :rows="10" :label="$t('components.supportButton.popup.inputBody')" />
      <bb-button :disabled="buttonDisabled" :loading="isSending" @click="send">
        {{ $t('components.supportButton.popup.sendButton') }}
      </bb-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useFloating, autoUpdate, shift, offset } from '@floating-ui/vue';

const dashboardCustomizationStore = useDashboardCustomization();
const buttonVisible = computed(() => dashboardCustomizationStore.zendeskBrandId);

const buttonRef = ref();
const popupRef = ref();
const { visible: popupVisible, show: showPopup, hide: hidePopup } = useHookModal('support');
const { floatingStyles } = useFloating(buttonRef, popupRef, {
  middleware: [offset(20), shift({ crossAxis: true, padding: 20 })],
  placement: 'top',
  whileElementsMounted: autoUpdate,
});
onClickOutside(popupRef, hidePopup);

const subject = ref('');
const body = ref('');
const buttonDisabled = computed(() => !subject.value || !body.value || isSending.value);

const { t } = useI18n();
const workspace = await useSelectedWorkspace();
const isSending = ref(false);
const send = () => {
  isSending.value = true;

  $fetch(`/api/workspaces/${workspace.value?.workspaceUuid}/support`, {
    method: 'POST',
    headers: useRequestHeaders(['cookie']),
    body: {
      subject: subject.value,
      body: body.value,
    },
  })
    .then(() => {
      useNotificationBanner(t('components.supportButton.popup.notification.success'), 'success', 5000);
      hidePopup();
      subject.value = '';
      body.value = '';
    })
    .catch(e => {
      useNotificationBanner(t('components.supportButton.popup.notification.error'), 'error', 5000);
      throw e;
    })
    .finally(() => {
      isSending.value = false;
    });
};
</script>
