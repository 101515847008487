<template>
  <navigation-sidebar-dropdown>
    <button type="button" class="my-8 flex w-full items-center justify-center">
      <fa-layers fixed-width class="fa-xl cursor-pointer">
        <fa-icon :icon="['fal', 'bell']" />
        <fa-layers-text
          v-if="unreadNotifications"
          counter
          :value="unreadNotifications"
          class="!bg-red-500"
          transform="right-16 down-7 shrink-9"
        />
      </fa-layers>
    </button>

    <template #popper="{ hide }">
      <div class="flex h-[19.625rem] w-[21.375rem] flex-col px-16 py-8">
        <h3 class="flex items-center justify-between border-b border-gray-200 py-8 dark:border-gray-600">
          <div v-t="'components.navigation.modal.notificationMenu.title'" class="text-gray-700 dark:text-gray-100" />
          <fa-icon :icon="['far', 'times']" class="cursor-pointer text-gray-800 dark:text-gray-50" @click="hide" />
        </h3>

        <div class="text-11 relative h-full overflow-y-auto pt-8">
          <div v-if="!notifications.length" class="flex h-full flex-col items-center justify-center gap-8">
            <fa-icon :icon="['fal', 'bell']" class="text-22 text-gray-600 dark:text-gray-400" />
            <div
              v-t="'components.navigation.modal.notificationMenu.empty'"
              class="font-semibold text-gray-700 dark:text-gray-100"
            />
            <div
              v-t="'components.navigation.modal.notificationMenu.caughtUp'"
              class="text-gray-700 dark:text-gray-100"
            />
          </div>
          <template v-else>
            <div
              v-if="hasUnreadNotifications"
              v-t="'components.navigation.modal.notificationMenu.markAllAsRead'"
              class="absolute right-0 top-0 cursor-pointer p-8 text-blue-500"
              @click="markAllAsRead"
            />
            <div v-if="filteredNotifications.today.length > 0" class="flex flex-col gap-8">
              <div
                v-t="'components.navigation.modal.notificationMenu.todaysNotifications'"
                class="text-11 text-gray-500 dark:text-gray-300"
              />
              <navigation-sidebar-notification-menu-item
                v-for="item in filteredNotifications.today"
                :key="item.notification.uuid"
                v-model="item.notification"
              />
            </div>
            <div v-if="filteredNotifications.yesterday.length > 0" class="flex flex-col gap-8">
              <div v-t="'components.navigation.modal.notificationMenu.yesterdaysNotifications'" />
              <navigation-sidebar-notification-menu-item
                v-for="item in filteredNotifications.yesterday"
                :key="item.notification.uuid"
                v-model="item.notification"
              />
            </div>
            <div v-if="filteredNotifications.older.length > 0" class="flex flex-col gap-8">
              <div v-t="'components.navigation.modal.notificationMenu.olderNotifications'" />
              <navigation-sidebar-notification-menu-item
                v-for="item in filteredNotifications.older"
                :key="item.notification.uuid"
                v-model="item.notification"
              />
            </div>
          </template>
        </div>
      </div>
    </template>
  </navigation-sidebar-dropdown>
</template>

<script setup lang="ts">
import type { Notification } from '../../types/notification';

const { data, refresh } = useFetch('/api/notifications', {
  method: 'GET',
  headers: useRequestHeaders(['cookie']) as Record<string, string>,
});

useIntervalFn(refresh, 1000 * 60 * 5);

const notifications = computed<Array<Notification>>(() => {
  if (data.value?.status !== 'success') {
    return [];
  }

  return data.value.data.notifications as unknown as Array<Notification>;
});
const unreadNotifications = computed(() => notifications.value.filter(notification => !notification.readAt).length);

const hasUnreadNotifications = computed(() => notifications.value.some(notification => !notification.readAt));

function categorizeByDate(notifications: Array<Notification>) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const categorizedObjects: {
    today: Array<{ notification: Notification }>;
    yesterday: Array<{ notification: Notification }>;
    older: Array<{ notification: Notification }>;
  } = {
    today: [],
    yesterday: [],
    older: [],
  };

  notifications.forEach(notification => {
    const notificationCreateDate = new Date(notification.createdAt);
    notificationCreateDate.setHours(0, 0, 0, 0);

    if (notificationCreateDate.getTime() === today.getTime()) {
      categorizedObjects.today.push({ notification: notification });
    } else if (notificationCreateDate.getTime() === yesterday.getTime()) {
      categorizedObjects.yesterday.push({ notification: notification });
    } else {
      categorizedObjects.older.push({ notification: notification });
    }
  });

  return categorizedObjects;
}

const filteredNotifications = computed(() => categorizeByDate(notifications.value));

const markAllAsRead = async () => {
  const notificationIds = notifications.value
    .filter(notification => !notification.readAt)
    .map(notification => notification.uuid);

  notifications.value.forEach(notification => (notification.readAt = new Date()));

  await useFetch('/api/notifications', {
    method: 'PATCH',
    headers: useRequestHeaders(['cookie']) as Record<string, string>,
    body: {
      notificationIds,
    },
  });
};
</script>
