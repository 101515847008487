<template>
  <navigation-sidebar-dropdown>
    <button type="button" class="flex w-full items-center justify-center">
      <image-icon frame="thin" :source="userStore.profilePicture" rounded />
    </button>
    <template #popper>
      <div class="flex w-[21.375rem] items-center gap-8 p-8">
        <div class="w-48 shrink-0">
          <image-icon :source="userStore.profilePicture" rounded frame="thin" />
        </div>
        <div>
          <div class="flex items-center">
            <div class="font-semibold text-gray-800 dark:text-gray-50" v-text="userStore.fullName" />
            <div
              v-if="badge"
              class="ml-8 rounded-full px-8 py-4 text-[0.5rem]"
              :class="{ 'text-gray-50': useBadgeLightTextColor }"
              :style="{ backgroundColor: `#${badge.badgeColor}` }"
              v-text="badge.name"
            />
          </div>
          <div class="text-11 text-gray-600 dark:text-gray-200" v-text="userStore.email" />
        </div>
      </div>
      <bb-select
        v-model="colorMode.preference"
        :label="$t('components.user.logoutModal.themeSwitchLabel')"
        name="preferred-color-mode"
        :options="colorModes"
        class="mb-16 mt-8"
      />
      <bb-button variant="outlined" size="sm" class="w-full" :disabled="isLoggingOut" @click="logout">
        {{ $t('components.user.logoutModal.logoutButton') }}
      </bb-button>
    </template>
  </navigation-sidebar-dropdown>
</template>

<script lang="ts" setup>
import { isLuminanceTextThresholdExceeded } from '@bitburst-gmbh/bitburst-colors';

const colorMode = useColorMode();

const userStore = useUserStore();

const isLoggingOut = ref(false);

const client = useSupabaseClient();

const { t } = useI18n();

const colorModes = ref([
  { label: t('components.user.logoutModal.themeSystem'), value: 'system' },
  { label: t('components.user.logoutModal.themeLight'), value: 'light' },
  { label: t('components.user.logoutModal.themeDark'), value: 'dark' },
]);

const logout = async () => {
  isLoggingOut.value = true;
  await client.auth.signOut();
  isLoggingOut.value = false;
  userStore.$reset();

  await navigateTo({ name: 'login' });
};

const useBadgeLightTextColor = computed(() => {
  if (!badge.value) return false;
  return !isLuminanceTextThresholdExceeded(`#${badge.value.badgeColor}`);
});

const badge = computed(() => {
  const roles = userStore.roles.filter(r => r.badgeColor !== '' || r.badgePriority !== 0);
  if (roles.length <= 0) {
    return null;
  }

  return roles.reduce((prev, current) => {
    if (!prev) return current;
    if (prev.badgePriority > current.badgePriority) return prev;
    return current;
  }, roles[0]);
});
</script>
