<template>
  <div
    class="rounded-4 flex min-w-[10rem] flex-row items-center gap-8 p-8"
    :class="{ 'cursor-pointer hover:bg-blue-50 dark:hover:bg-blue-800': !selected }"
    @click="selectWorkspace"
  >
    <image-icon v-if="workspace" :source="workspace.iconImage" class="shrink-0" />
    <div class="truncate">
      <div class="text-13 truncate font-semibold text-gray-800 dark:text-gray-50">{{ workspace?.name }}</div>
      <div
        v-if="selected"
        v-t="'components.navigation.workspaceSelectItem.selectWorkspace'"
        class="text-11 truncate text-gray-600 dark:text-gray-200"
      />
      <div v-else v-t="'components.navigation.workspaceSelectItem.switchWorkspace'" class="text-11 text-blue-500" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { WorkspaceDetails } from '../../types/workspace';

const props = defineProps<{
  workspace: null | WorkspaceDetails;
  selected?: boolean;
}>();

const emits = defineEmits<(e: 'workspace-select', value: WorkspaceDetails) => void>();

const selectWorkspace = () => {
  if (!props.selected && props.workspace) {
    emits('workspace-select', props.workspace);
  }
};
</script>
