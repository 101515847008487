<template>
  <navigation-sidebar-dropdown>
    <button
      v-if="selectedWorkspaceCookie"
      class="dark:border-blue-10 flex w-full flex-row items-center justify-center border-t border-blue-900 px-8 py-12"
      type="button"
    >
      <image-icon :source="selectedWorkspaceCookie.iconImage" size="s" class="mr-4 shrink-0" />
      <div class="text-11 overflow-hidden truncate" v-text="selectedWorkspaceCookie.name" />
      <fa-icon :icon="['far', 'chevron-right']" class="text-11 mb-[-0.125rem] ml-4" />
    </button>
    <template #popper="{ hide }">
      <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between sm:gap-16">
        <navigation-sidebar-workspace-select-menu-item :workspace="selectedWorkspaceCookie" selected />
        <div class="p-8">
          <bb-button variant="outlined" size="sm" @click="() => [hide(), goToWorkspaceDetailPage()]">
            {{ $t('components.navigation.workspaceSelect.viewButton') }}
          </bb-button>
        </div>
      </div>
      <div
        v-if="areWorkspacesAvailable"
        v-t="'components.navigation.workspaceSelect.availableWorkspaces'"
        class="text-13 p-8 text-gray-600 dark:text-gray-200"
      />

      <div class="grid w-fit grid-cols-1 grid-rows-4 sm:grid-cols-2 sm:grid-rows-2">
        <template v-if="pending">
          <navigation-sidebar-workspace-select-menu-skeleton-item v-for="i in 4" :key="i" />
        </template>
        <template v-else>
          <navigation-sidebar-workspace-select-menu-item
            v-for="workspace in availableWorkspaces"
            :key="workspace.details.workspaceUuid"
            :workspace="workspace.details"
            @workspace-select="setSelectedWorkspace"
          />
        </template>
      </div>
      <pagination-filter v-if="areWorkspacesAvailable" v-model="page" :max-pages="maxPages" class="p-8" />
    </template>
  </navigation-sidebar-dropdown>
</template>

<script setup lang="ts">
import type { WorkspaceDetails } from '../../types/workspace';

const selectedWorkspaceCookie = await useSelectedWorkspace();

const selectedWorkspaceUuid = computed(() => selectedWorkspaceCookie.value?.workspaceUuid ?? '');

const setSelectedWorkspace = async (workspace: WorkspaceDetails) => {
  selectedWorkspaceCookie.value = workspace;
  if (useRoute().name === 'workspaces-id') {
    await navigateTo({ name: 'workspaces-id', params: { id: workspace.workspaceUuid } });
  }
};

const page = ref(1);

const { data, error, pending } = await useFetch('/api/workspaces', {
  method: 'GET',
  headers: useRequestHeaders(['cookie']) as Record<string, string>,
  query: { page, limit: 4, exclude: selectedWorkspaceUuid },
});

const maxPages = computed(() => data.value?.data.maxPages ?? 1);
const availableWorkspaces = computed(() => data.value?.data.workspaces ?? []);

const areWorkspacesAvailable = computed(() => availableWorkspaces.value.length !== 0);

watch(error, err => {
  if (!err) return;
  throw createError({
    statusCode: err.statusCode,
    message: err.message,
  });
});

const goToWorkspaceDetailPage = async () => {
  await useRouter().push({
    name: 'workspaces-id',
    params: { id: selectedWorkspaceCookie.value?.workspaceUuid },
  });
};
</script>
