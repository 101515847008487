<template>
  <nuxt-link
    class="rounded-4 relative flex w-full cursor-pointer justify-between p-8 hover:bg-blue-50 dark:hover:bg-blue-800"
    :to="notification.routeData ? notification.routeData : {}"
    @click="onClick"
  >
    <div class="flex">
      <image-icon :source="imageIconSource" class="mr-8" size="s" rounded />
      <div class="text-11">
        <h4 class="font-semibold text-gray-700 dark:text-gray-100">
          {{
            $t(
              `components.navigation.modal.notificationMenuItem.category.${notification.category}`,
              notification.translationData
            )
          }}
        </h4>
        <div class="text-gray-400" v-text="getPastTimeAsString" />
      </div>
    </div>

    <div v-if="!notification.readAt" class="relative flex h-8 w-8 shrink-0 items-center justify-center">
      <span class="animate-skeleton absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
      <span class="relative inline-flex h-4 w-4 rounded-full bg-blue-500"></span>
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
import type { Notification } from '../../types/notification';

const props = defineProps<{
  modelValue: Notification;
}>();

const emit = defineEmits<(e: 'update:modelValue', value: Notification) => void>();

const { t } = useI18n();

const notification = useVModel(props, 'modelValue', emit);

const selectedWorkspaceCookie = await useSelectedWorkspace();

const getPastTimeAsString = computed(() => {
  const now = new Date();
  const createdAt = new Date(notification.value.createdAt);

  const diff = now.getTime() - createdAt.getTime();
  const diffInHours = diff / (1000 * 3600);
  const diffInDays = diffInHours / 24;
  const diffInWeeks = diffInDays / 7;

  if (diffInHours < 1) {
    return t('components.navigation.modal.notificationMenuItem.pastTime.lessThanAnHour');
  } else if (diffInHours < 24) {
    return t('components.navigation.modal.notificationMenuItem.pastTime.hours', {
      hours: Math.floor(diffInHours),
    });
  } else if (diffInDays < 2) {
    return t('components.navigation.modal.notificationMenuItem.pastTime.yesterday');
  } else if (diffInDays < 7) {
    return t('components.navigation.modal.notificationMenuItem.pastTime.days', {
      days: Math.floor(diffInDays),
    });
  } else {
    return t('components.navigation.modal.notificationMenuItem.pastTime.weeks', {
      weeks: Math.floor(diffInWeeks),
    });
  }
});
const imageIconSource = computed(() => {
  return (
    selectedWorkspaceCookie.value?.iconImage ?? `/api/letters/${selectedWorkspaceCookie.value?.name.substring(0, 2)}`
  );
});

const onClick = async () => {
  const eventData = notification.value.eventData;
  if (eventData !== null) {
    await useHooks().callHook(eventData.name, eventData.args);
  }

  await markAsRead();
};

const markAsRead = async () => {
  if (notification.value.readAt) return;

  await useFetch(`/api/notifications`, {
    method: 'PATCH',
    headers: useRequestHeaders(['cookie']) as Record<string, string>,
    body: {
      notificationIds: [notification.value.uuid],
    },
  });
  notification.value.readAt = new Date();
};
</script>
