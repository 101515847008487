<template>
  <nav
    class="bg-navigation-800 text-navigation-contrast-500 flex w-[var(--navigation-width)] flex-col justify-between overflow-y-auto pt-24"
    aria-labelledby="primary-navigation"
  >
    <h2 id="primary-navigation" v-t="'components.navigation.sidebar.primarySectionLabel'" class="sr-only" />
    <div class="flex flex-col content-center items-center">
      <img :src="navigationLogoUrl" :alt="$t('components.navigation.sidebar.logoAltText')" class="mb-24 h-32 px-12" />
      <ul class="w-full">
        <li v-for="item in menuItems" :key="item.label">
          <navigation-menu-item :icon="item.icon" :label="item.label" :to="item.to" class="mb-24" />
        </li>
      </ul>
    </div>
    <div class="flex flex-col items-center justify-center gap-16">
      <navigation-sidebar-notification-menu />
      <navigation-sidebar-user-menu />
      <navigation-sidebar-workspace-select-menu />
    </div>
  </nav>
</template>

<script setup lang="ts">
import { subject } from '@casl/ability';

const { t } = useI18n();
const { can } = useAppAbility();

const workspace = await useSelectedWorkspace();
const { navigationLogoUrl } = storeToRefs(useDashboardCustomization());

//TODO - refactor and remove this to use abilityWorkspaceSubject from workspace store
const { data } = useFetch(`/api/workspaces/${workspace.value?.workspaceUuid}`, {
  method: 'GET',
  headers: useRequestHeaders(['cookie']),
});

const abilityWorkspaceSubject = computed(() => ({
  workspaceUuid: workspace.value?.workspaceUuid ?? '',
  workspaceRole: data.value?.data.workspaceRole ?? 'NONE',
}));

const menuItems = computed(() => {
  const items = [
    {
      icon: 'grid-2',
      label: t('components.navigation.sidebar.menuItemLabel.apps'),
      to: { name: 'apps' },
    },
    {
      icon: 'briefcase',
      label: t('components.navigation.sidebar.menuItemLabel.workspace'),
      to: workspace.value
        ? { name: 'workspaces-id', params: { id: workspace.value.workspaceUuid } }
        : { name: 'workspaces' },
    },
    {
      icon: 'user',
      label: t('components.navigation.sidebar.menuItemLabel.account'),
      to: { name: 'account' },
    },
  ];

  if (can('read', subject('Workspace', abilityWorkspaceSubject.value), 'workspace.analytics')) {
    items.unshift({
      icon: 'file-magnifying-glass',
      label: t('components.navigation.sidebar.menuItemLabel.dashboard'),
      to: { name: 'dashboard' },
    });
  }

  if (can('read', 'Management', 'management.publishers')) {
    items.push({
      icon: 'address-book',
      label: t('components.navigation.sidebar.menuItemLabel.publishers'),
      to: { name: 'management.publisher' },
    });
  }

  if (can('read', 'Management', 'management.users')) {
    items.push({
      icon: 'user-group',
      label: t('components.navigation.sidebar.menuItemLabel.users'),
      to: { name: 'management.users' },
    });
  }

  if (can('read', 'Management', 'management.apps')) {
    items.push({
      icon: 'grid-2',
      label: t('components.navigation.sidebar.menuItemLabel.publisherApps'),
      to: { name: 'management.apps' },
    });
  }

  if (can('read', 'Management', 'management.roles')) {
    items.push({
      icon: 'user-unlock',
      label: t('components.navigation.sidebar.menuItemLabel.roles'),
      to: { name: 'management.roles' },
    });
  }

  return items;
});
</script>
