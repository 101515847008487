const useHookModal = (name: string, visible = false) => {
  const hooks = useHooks();
  const _visible = ref(visible);

  const show = () => hooks.callHook('modal:show', { name });
  const showHandler = (event: { name: string }) => {
    if (event.name === name) _visible.value = true;
  };
  hooks.hook('modal:show', showHandler);

  const hide = () => hooks.callHook('modal:hide', { name });
  const hideHandler = (event: { name: string }) => {
    if (event.name === name) _visible.value = false;
  };
  hooks.hook('modal:hide', hideHandler);

  onUnmounted(() => {
    hooks.removeHook('modal:show', showHandler);
    hooks.removeHook('modal:hide', hideHandler);
  });

  return { visible: _visible, show, hide };
};

export { useHookModal };
